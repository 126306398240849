import { Model } from "Figured/Assets/Modules/vuemc";


export default class SsoIdp extends Model
{
    defaults() {
        return {
            name: null,
            issuer: null,
            authnRequestUrl: null,
        }
    }
}
